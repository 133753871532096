export const useRequestCounter = (key: string) => {
  const requestCount = useState(`requestCounter:${key}`, () => 0)

  const increment = () => {
    requestCount.value++
  }

  const decrement = () => {
    if (requestCount.value > 0) {
      requestCount.value--
    }
  }

  return {
    visible: computed(() => requestCount.value > 0),
    increment,
    decrement,
  }
}
